import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      zIndex: theme.zIndex.drawer + 1,
    },
    hide: {
      display: 'none',
    },
    appToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    toolbar: theme.mixins.toolbar,
    fullWidth: {
      width: '100%',
    },
    menuButton: {
      display: 'block',
      float: 'right',
    },
    headerText: {
      color: 'white',
    }
  }),
);

interface MainToolbarProps {
  drawerVisible: boolean,
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>,
}

export const MainToolbar = React.forwardRef<HTMLDivElement, MainToolbarProps>(({ drawerVisible, setDrawerVisible }: MainToolbarProps, ref) => {

  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={`${classes.root} ${classes.fullWidth}`}
      ref={ref}
      color="secondary"
    >
      <Toolbar className={classes.appToolbar}>
        {/* <Typography variant="h4" className={classes.headerText} noWrap>
          Oprawiamywramy - wizualizator ram
        </Typography> */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerVisible(!drawerVisible)}
          edge="end"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
});