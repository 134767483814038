import { AppBar, Button, createStyles, Dialog, makeStyles, Paper, Slide, Slider, Theme, Toolbar, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close, CloudUpload } from "@material-ui/icons";
import { debounce } from "lodash";
import log from "loglevel";
import React, { forwardRef, Ref, useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { getCroppedImg } from "../../helpers/canvasUtils";
import { PreviewState } from "../../types/PreviewState";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: '90%',
      background: '#333',
      // [theme.breakpoints.up('sm')]: {
      //     height: 400,
      // },
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    sliderContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      marginLeft: 16,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px',
      },
    },
    inputFullWidth: {
      width: '100%',
    },
    uploadButtonContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropImagePanel() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const { previewState, setPreviewState } = usePreviewContext();

  console.log(crop);
  console.log(croppedAreaPixels);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      const { uploadedImage, ...oldState } = previewState;
      setPreviewState({ uploadedImage: croppedImage, ...oldState });
      setOpen(false);
    } catch (e) {
      log.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation, previewState.frameHeight, previewState.frameWidth, previewState.frameSize])

  const onClose = useCallback(() => {
    setImageSrc(null);
    const { uploadedImage, ...oldState } = previewState;
    setPreviewState({ uploadedImage: null, ...oldState });
  }, [])

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl)
    }
  }

  const calculateAspectRatio = useCallback(
    () => {
      if (previewState.frameContent === "glass") {
        if (previewState.frameSize === "frame" && previewState.ppWidth && previewState.frame?.width) {
          let result = (previewState.frameWidth - (2 * (parseFloat(previewState.frame.width.toString(10)) + parseFloat(previewState.ppWidth.toString(10))))) /
            (previewState.frameHeight - (2 * (parseFloat(previewState.frame.width.toString(10)) + parseFloat(previewState.ppWidth.toString(10)))));
          return result;
        }
      }
      return previewState.frameWidth / previewState.frameHeight;
    },
    [previewState.frameContent, previewState.frameWidth, previewState.frameHeight, previewState.ppWidth, previewState.frameSize],
  );

  useEffect(() => {
    async function recropImage() {
      if (previewState.uploadedImage) {
        await showCroppedImage();
      }
    }
    recropImage();
  }, [previewState.frameHeight, previewState.frameWidth, previewState.frameSize])

  return (
    <div className={classes.inputFullWidth}>
      <Button
        className={classes.inputFullWidth}
        variant="contained"
        color="secondary"
        component="label"
        startIcon={<CloudUpload />}
        onClick={handleClickOpen}
      >
        Wczytaj swój obraz
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
            >
              {imageSrc ? "Wykadruj swój obraz" : "Wczytaj swój obraz"}
            </Typography>
            <Button
              startIcon={<CloudUpload />}
              color="inherit"
              onClick={onClose}
              aria-label="Close"
            >
              Wczytaj inny obrazek
            </Button>
            <Button
              startIcon={<Close />}
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              Zamknij
            </Button>
          </Toolbar>
        </AppBar>
        {imageSrc ? (
          <React.Fragment>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc!}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={calculateAspectRatio()}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Przybliż
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  color="secondary"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom as number)}
                />
              </div>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Obrót
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={90}
                  color="secondary"
                  aria-labelledby="Rotation"
                  classes={{ root: classes.slider }}
                  onChange={(e, rotation) => setRotation(rotation as number)}
                />
              </div>
              <Button
                onClick={showCroppedImage}
                variant="contained"
                color="primary"
                classes={{ root: classes.cropButton }}
              >
                Wczytaj
              </Button>
            </div>
            {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
          </React.Fragment>
        ) : (
          <Paper
            className={classes.uploadButtonContainer}
          >
            <Button
              variant="contained"
              component="label"
              color='primary'
              aria-label='upload-image'
            >
              Wczytaj swój obrazek
              <input type="file" hidden onChange={onFileChange} accept="image/*" />
            </Button>
          </Paper>
        )}
      </Dialog>
    </div>
  );
}

function readFile(file: File) {
  return new Promise<any>(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}