import { createContext, PropsWithChildren, useContext, useState } from "react";
import { initialPPColorsState, PPColorsState } from "../types/PPColorsState";

interface PPColorsContextType {
    pPColorsState: PPColorsState,
    setPPColorsState: (state: PPColorsState) => void,
}

const PPColorsContext = createContext<PPColorsContextType>({
    pPColorsState: initialPPColorsState,
    setPPColorsState: () => { },
});

export function usePPColorsContext() {
    return useContext(PPColorsContext);
}

export function PPColorsContextProvider({ children }: PropsWithChildren<PPColorsContextType>) {
    const [pPColorsState, setPPColorsState] = useState<PPColorsState>(initialPPColorsState);

    return (
        <PPColorsContext.Provider
            value={{
                pPColorsState,
                setPPColorsState: (newState: PPColorsState) => setPPColorsState(newState),
            }}
        >
            {children}
        </PPColorsContext.Provider>
    );
}