import { Checkbox, Chip, createStyles, FormControl, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select, Theme, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Filter, FilterGroup } from "../../types/Filter";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 352,
    },
  },
};

export interface FilterMultiSelectProps {
  filterGroup: FilterGroup,
  selectedFilters: Filter[],
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void,
  handleDelete: (e: React.MouseEvent, filter: string, filterGroup: string) => void,
  className?: string,
}

export function FilterMultiSelect({ filterGroup, selectedFilters, onChange, handleDelete, className }: FilterMultiSelectProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <FormControl className={`${classes.formControl} ${className}`}>
      <InputLabel id="demo-mutiple-chip-label">{filterGroup.filterHeader}</InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        variant="outlined"
        color="secondary"
        value={selectedFilters.map(filter => filter.name)}
        onChange={onChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((value) => (
              <Chip
                key={value}
                label={value}
                className={classes.chip}
                clickable
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, value, filterGroup.filterName)}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {filterGroup.filters.map((filter: Filter) => (
          <MenuItem key={filter.id} value={filter.id}>
            <Checkbox checked={selectedFilters.includes(filter)} />
            <ListItemText primary={filter.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}