import { FilterGroup } from "./Filter";

export type FiltersState = {
    search: string,
    filterGroups: FilterGroup[],
    appliedFilters: number[],
}

export const initialFiltersState: FiltersState = {
    search: '',
    filterGroups: [
        { filterName: "pa_kolor", filterHeader: "Kolor", filters: [] },
        { filterName: "pa_styl", filterHeader: "Styl", filters: [] },
        { filterName: "pa_zastosowanie", filterHeader: "Zastosowanie", filters: [] },
        { filterName: "pa_szerokosc", filterHeader: "Szerokość", filters: [] },
    ],
    appliedFilters: [],
};