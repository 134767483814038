import { createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { ColorPicker } from "./ColorPicker";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { initialPreviewState } from "../../types/PreviewState";
import CropImagePanel from "./CropImagePanel";
import { Color } from "../../types/Color";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: 0,
      padding: theme.spacing(3),
      backgroundColor: 'inherit',
      boxSizing: 'border-box',
    },
    subcontainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    horizontalContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    input: {
      marginTop: theme.spacing(3),
    },
    inputFullWidth: {
      width: '100%',
    }
  }),
);

const colors: Color[] = [
  {
    ID: 1,
    Code: "1",
    Color: "#FFFFFF"
  },
  {
    ID: 2,
    Code: "2",
    Color: "#FFFEE1"
  },
  {
    ID: 3,
    Code: "3",
    Color: "#FFFFCA"
  },
  {
    ID: 4,
    Code: "4",
    Color: "#FEF1C9"
  },
  {
    ID: 6,
    Code: "6",
    Color: "#CD7054"
  },
  {
    ID: 7,
    Code: "7",
    Color: "#E4E4E4"
  },
  {
    ID: 8,
    Code: "8",
    Color: "#A7A7A7"
  },
  {
    ID: 9,
    Code: "9",
    Color: "#313131"
  },
  {
    ID: 10,
    Code: "10",
    Color: "#578CB5"
  },
  {
    ID: 11,
    Code: "11",
    Color: "#324965"
  },

  {
    ID: 12,
    Code: "12",
    Color: "#AEE4AE"
  },
  {
    ID: 13,
    Code: "13",
    Color: "#bdd176"
  },
  {
    ID: 14,
    Code: "14",
    Color: "#cfbf3b"
  },
  {
    ID: 15,
    Code: "15",
    Color: "#008080"
  },
  {
    ID: 16,
    Code: "16",
    Color: "#2e8f3d"
  },
  {
    ID: 17,
    Code: "17",
    Color: "#FFAEAE"
  },
  {
    ID: 18,
    Code: "18",
    Color: "#f08948"
  },
  {
    ID: 19,
    Code: "19",
    Color: "#FF5656"
  },
  {
    ID: 20,
    Code: "20",
    Color: "#701010"
  },
  {
    ID: 21,
    Code: "21",
    Color: "#87421F"
  }]

export function PreviewSettingsPanel() {
  const classes = useStyles();

  const { previewState, setPreviewState } = usePreviewContext();

  const onColorPickerClicked = (color: Color) => {
    if (previewState) {
      const { wallColor, ...oldAppState } = previewState;
      setPreviewState({ ...oldAppState, wallColor: color });
    }
    else {
      const { wallColor, ...oldAppState } = initialPreviewState;
      setPreviewState({ ...oldAppState, wallColor: color })
    }
  }

  return (
    <Paper className={classes.container} elevation={0}>
      {'glass' === previewState.frameContent && <CropImagePanel />}

      <Typography className={classes.input}>Wybierz kolor ściany:</Typography>
      <ColorPicker
        className={`${classes.input} ${classes.inputFullWidth}`}
        colors={colors}
        tileSize={8}
        value={previewState?.wallColor}
        onClick={onColorPickerClicked}
      />
    </Paper>
  );
}