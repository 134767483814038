import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4a97c2',
      dark: '#1a1a17',
    },
    secondary: {
      main: '#e79292', //Another orange-ish color
    },
  },
  typography: {
    h1: {
      fontFamily: 'KGLifeisMessy',
    },
    h2: {
      fontFamily: 'KGLifeisMessy',
    },
    h3: {
      fontFamily: 'KGLifeisMessy',
    },
    h4: {
      fontFamily: 'KGLifeisMessy',
    },
    h5: {
      fontFamily: 'KGLifeisMessy',
    },
    h6: {
      fontFamily: 'Lato',
    },
    fontFamily: 'Lato',
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
