import { createStyles, easing, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import log from "loglevel";
import { useFiltersContext } from "../contexts/FiltersContext";
import { useProductsContext } from "../contexts/ProductsContext";
import { FrameFilter } from "../components/FrameFilter/FrameFilter";
import { FrameList } from "../components/FrameFilter/FrameList";
import { Frame } from "../types/Frame";
import { apiUrl } from "../settings/apiUrl";
import { MainToolbar } from "../components/MainToolbar/MainToolbar";

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: (drawerVisible) => drawerVisible ? `calc(100% - ${drawerWidth}px)` : '100%',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

export function MainPage() {

  const { productsState, setProductsState } = useProductsContext();
  const { filtersState, setFiltersState } = useFiltersContext();
  const [drawerVisible, setDrawerVisible] = useState(true);
  const [toolbarHeight, setToolbarHeight] = useState(0)
  const toolbarRef = useRef<HTMLDivElement>(null)

  const classes = useStyles(drawerVisible);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setToolbarHeight(toolbarRef?.current?.clientHeight ?? 0);
  });

  useEffect(() => {
    if (smallScreen) {
      setDrawerVisible(false);
    }
  }, [smallScreen]);

  useEffect(() => {
    async function getProducts() {
      setProductsState({ framesList: [], isLoading: true });
      let newFrames: Frame[] = [];
      if (filtersState.search !== '') {
        let url = `${apiUrl}/wp/products/search/`;
        url += filtersState.search;
        newFrames = await axios.get(url).then(res => {
          let newFrames: Frame[] = [];
          res.data.map((frame: Frame) => newFrames.push(frame));
          return newFrames;
        }).catch(error => {
          log.error(error);
          return [];
        })
      } else {
        let url = `${apiUrl}/wp/products/`;
        filtersState.appliedFilters.forEach(filter => url += `${filter}-`);
        url = url.slice(0, -1);
        newFrames = await axios.get(url).then(res => {
          let newFrames: Frame[] = [];
          res.data.map((frame: Frame) => newFrames.push(frame));
          return newFrames;
        }).catch(error => {
          log.error(error);
          return [];
        })
      }

      setProductsState({ framesList: newFrames, isLoading: false });
    }
    getProducts();
  }, [filtersState.appliedFilters, filtersState.search, setFiltersState]);


  return (
    <>
      {
        smallScreen && <MainToolbar
          ref={toolbarRef}
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      }
      {drawerVisible && <FrameFilter productsCount={productsState.framesList.length} drawerMargin={toolbarHeight} />}
      <FrameList
        headerMargin={toolbarHeight}
        framesList={productsState.framesList}
        isLoading={productsState.isLoading}
        className={classes.appBar}
      />
    </>
  );
}