import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";

export interface NumericInputProps {
  label: string,
  id: string,
  value?: number,
  color?: "primary" | "secondary" | undefined,
  classes?: any,
  validation?: boolean,
  className?: string,
  validationMessage?: string,
  error?: boolean,
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export interface NumericInputState {
  value: number,
  error: boolean,
  validationMessage: string,
}

export function NumericInput({ label, id, value, color, classes, validation, className, validationMessage, error, handleChange }: NumericInputProps) {

  return (
    <TextField
      color={color ? color : "secondary"}
      classes={classes}
      size="small"
      className={className}
      error={validation && error}
      id={id}
      label={label}
      helperText={validation && error && validationMessage}
      value={value}
      variant="outlined"
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
        style: {
          padding: 1
        }
      }}
      onChange={handleChange}
    />
  );
}