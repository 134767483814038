import { Box, createStyles, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Paper, Radio, RadioGroup, Select, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { initialPreviewState } from "../../types/PreviewState";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 0,
            padding: theme.spacing(3),
            backgroundColor: 'inherit',
        },
        subcontainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        horizontalContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        radio: {
            marginTop: theme.spacing(1),
        },
        selectBox: {
            margin: theme.spacing(1),
            width: '400px',
        },
        selectBoxRoot: {
            padding: '10.5px',
        },
    }),
);

interface ContentState {
    content: string,
    glassType: string,
}

export function FrameContentPanel() {
    const classes = useStyles();
    const [content, setContent] = useState<ContentState>({
        content: "glass",
        glassType: "2"
    });

    const { previewState: appState, setPreviewState: setAppState } = usePreviewContext();

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (appState) {
            const { frameContent, ...oldAppState } = appState;
            setAppState({ ...oldAppState, frameContent: event.target.value as "empty" | "glass" | "chalkBoard" | "corkBoard" | "mirror" });
        }
        else {
            const { frameContent, ...oldAppState } = initialPreviewState;
            setAppState({ ...oldAppState, frameContent: event.target.value as "empty" | "glass" | "chalkBoard" | "corkBoard" | "mirror" })
        }
    }

    const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (appState?.frameContent === "glass") {
            setContent({ content: content.content, glassType: (event.target.value as string) });
            if (appState) {
                const { glass, ...oldAppState } = appState;
                setAppState({ ...oldAppState, glass: event.target.value as "2" | "3" | "a" | "p" });
            }
            else {
                const { glass, ...oldAppState } = initialPreviewState;
                setAppState({ ...oldAppState, glass: event.target.value as "2" | "3" | "a" | "p" })
            }
        }
    }
    return (
        <Paper className={classes.container} elevation={0}>
            <RadioGroup name="frameContent" defaultValue="glass" value={appState?.frameContent} className={classes.subcontainer} onChange={handleChangeRadio}>
                <FormControlLabel
                    className={classes.radio}
                    value="empty"
                    label="Brak - zamawiam tylko ramę"
                    control={<Radio color="secondary" />}
                />
                <Box className={classes.horizontalContainer}>
                    <FormControlLabel
                        value="glass"
                        label="Szyba"
                        control={<Radio color="secondary" />}
                    />
                    {appState?.frameContent === "glass" &&
                        <FormControl className={classes.selectBox} variant="outlined">
                            <InputLabel id="select-glass-type-label">Wybierz rodzaj szyby</InputLabel>
                            <Select
                                autoWidth
                                classes={{
                                    root: classes.selectBoxRoot,
                                }}
                                labelId="select-glass-type-label"
                                id="select-glass-type"
                                label="Wybierz rodzaj szyby"
                                value={appState?.glass}
                                onChange={handleChangeSelect}
                                color="secondary"
                            >
                                <MenuItem value={'2'}>Szyba 2mm (standardowe, błyszczące szkło)</MenuItem>
                                <MenuItem value={'3'}>Szyba 3mm (standardowe, błyszczące szkło do formatów powyżej 100x70 cm)</MenuItem>
                                <MenuItem value={'a'}>Szyba antyrefleksyjna (szkło matowe, ziarniste, nieodbijające światła)</MenuItem>
                                <MenuItem value={'p'}>Pleksi (błyszczące, lekkie tworzywo sztuczne)</MenuItem>
                            </Select>
                        </FormControl>
                    }
                </Box>

                <FormControlLabel
                    className={classes.radio}
                    value="chalkBoard"
                    label="Tablica kredowa"
                    control={<Radio />}
                />
                <FormControlLabel
                    className={classes.radio}
                    value="corkBoard"
                    label="Tablica korkowa"
                    control={<Radio />}
                />
                <FormControlLabel
                    className={classes.radio}
                    value="mirror"
                    label="Lustro"
                    control={<Radio />}
                />
            </RadioGroup>
        </Paper>
    );
}