import { createContext, PropsWithChildren, useContext, useState } from "react";
import { PreviewState, initialPreviewState } from "../types/PreviewState";

interface PreviewContextType {
    previewState: PreviewState,
    setPreviewState: (state: PreviewState) => void,
}

const PreviewContext = createContext<PreviewContextType>({
    previewState: initialPreviewState,
    setPreviewState: () => { },
});

export function usePreviewContext() {
    return useContext(PreviewContext);
}

export function PreviewContextProvider({ children }: PropsWithChildren<PreviewContextType>) {
    const [previewState, setPreviewState] = useState<PreviewState>(initialPreviewState);

    return (
        <PreviewContext.Provider
            value={{
                previewState: previewState,
                setPreviewState: (newState: PreviewState) => setPreviewState(newState),
            }}
        >
            {children}
        </PreviewContext.Provider>
    );
}