import { Frame } from "./Frame";

export type ProductsState = {
    isLoading: boolean,
    framesList: Frame[],
}

export const initialProductsState: ProductsState = {
    isLoading: false,
    framesList: [],
};