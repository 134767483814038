import { Color } from "../types/Color";
import { initialPreviewState, PreviewState } from "../types/PreviewState";

export function paramsToState(paramsString: string, ppColors: Color[]): PreviewState | undefined {
  if (paramsString && ppColors.length > 0) {
    const params = new URLSearchParams(paramsString);
    let state: PreviewState = initialPreviewState;
    state.frameWidth = Number.parseInt(params.get('szerokosc') ?? '30', 10);
    state.frameHeight = Number.parseInt(params.get('wysokosc') ?? '40', 10);

    switch (params.get('typ_wymiar')) {
      case 'rama_zewn':
        state.frameSize = 'frame';
        break;
      case 'obraz':
      default:
        state.frameSize = 'frameInner';
        break;
    }

    switch (params.get('szyba')) {
      case '2':
        state.glass = '2';
        state.frameContent = 'glass';
        break;
      case '3':
        state.glass = '3';
        state.frameContent = 'glass';
        break;
      case 'a':
        state.glass = 'a';
        state.frameContent = 'glass';
        break;
      case 'p':
        state.glass = 'p';
        state.frameContent = 'glass';
        break;
      default:
        state.glass = '2';
        state.frameContent = 'empty';
        break;
    }

    const ppWidth = Number.parseInt(params.get('pp') ?? '-1', 10);
    if (ppWidth > 0) {
      state.ppWidth = ppWidth;
    }

    const idPPColor = Number.parseInt(params.get('id_pp_kolor') ?? '-1', 10);
    if (idPPColor > 0) {
      const color = ppColors.find(c => c.ID === idPPColor);
      if (color) {
        state.ppColor = color;
      }

    }

    if (Number.parseInt(params.get('tablica_kredowa') ?? '-1', 10) > 0) {
      state.frameContent = 'chalkBoard';
    }
    if (Number.parseInt(params.get('tablica_korkowa') ?? '-1', 10) > 0) {
      state.frameContent = 'corkBoard';
    }
    if (Number.parseInt(params.get('lustro') ?? '-1', 10) > 0) {
      state.frameContent = 'mirror';
    }

    return state;
  }
}

export function stateToParams(state: PreviewState): string | undefined {
  if (state.frame?.url) {
    let result = state.frame?.url;
    result += `?size=${state.frameSize === 'frame' ? 'full' : 'picture'}`;
    result += `&width=${state.frameWidth}`;
    result += `&height=${state.frameHeight}`;

    result += '&component=';
    switch (state.frameContent) {
      case 'empty':
        result += 'no';
        break;
      case 'glass':
        result += 'glass';
        if (state.glass) {
          result += `,${state.glass}`;
        }
        break;
      case 'chalkBoard':
        result += 'chalkboard';
        break;
      case 'corkBoard':
        result += 'pinboard';
        break;
      case 'mirror':
        result += 'mirror';
        break;
    }
    if (state.ppContent) {
      result += '&pp=true';
      if (state.ppWidth) {
        result += `,${state.ppWidth}`;
      }
      if (state.ppColor) {
        result += `,${state.ppColor.ID}`;
      }
    }
    return result;
  }
  return undefined;
}