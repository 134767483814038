import { CircularProgress, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { useFiltersContext } from "../../contexts/FiltersContext";
import { Frame } from "../../types/Frame";
import { FrameBox } from "./FrameBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: (headerMargin) => `${headerMargin}px`,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    frameListBox: {
      minHeight: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: `repeat(auto-fill, ${220 + theme.spacing(4)}px)`,
        padding: 20,
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    headerFullWidth: {
      display: 'flex',
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      // borderBottomWidth: 1,
      // borderBottomStyle: 'solid',
      // borderBottomColor: theme.palette.secondary.main,
    },
    isLoading: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    circularProgress: {
      margin: 'auto',
    },
    h4: {
      textAlign: 'center',
      width: '100%',
    }
  }),
);

interface FrameListProps {
  isLoading: boolean,
  framesList: Frame[],
  headerMargin: number,
  className?: string,
}

export function FrameList({ className, isLoading, framesList, headerMargin }: FrameListProps) {
  const classes = useStyles(headerMargin);

  const { filtersState } = useFiltersContext();

  return (
    <Paper className={`${classes.container} ${className}`}>
      <div className={`${classes.headerFullWidth}`}>
        <Typography
          className={classes.h4}
          variant="h4"
          color="secondary"
          component="h4"
          align="center"
        >
          {
            (filtersState.appliedFilters.length === 0 && filtersState.search === '') ?
              'Wszystkie Ramki' : 'Wybrane Ramki'
          }
        </Typography>
      </div>
      <Paper className={isLoading ? classes.isLoading : classes.frameListBox} elevation={0}>
        {
          isLoading && <CircularProgress className={classes.circularProgress} color="secondary" />
        }
        {
          !isLoading && framesList.map((frame: Frame) => {
            return (
              <FrameBox frame={frame} key={frame.sku} />
            );
          })
        }
      </Paper>
    </Paper>
  );
}