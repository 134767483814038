import { Card, CardActionArea, CardContent, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { Frame } from "../../types/Frame";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'inherit',
    },
    subcontainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 220,
      margin: theme.spacing(2),
      backgroundColor: 'inherit',
    },
    media: {
      height: 220,
      width: 220,
    },
  }),
);

export interface FrameBoxProps {
  frame: Frame,
}

export function FrameBox({ frame }: FrameBoxProps) {
  const classes = useStyles();
  const history = useHistory();
  const { previewState, setPreviewState } = usePreviewContext();

  const handleCardClick = useCallback(
    () => {
      const { frameImage, ...oldState } = previewState;
      setPreviewState({ frameImage: undefined, ...oldState });
      history.push(`/rama/${frame.sku}`)
    },
    [history, frame.sku],
  )

  return (
    <>
      <Card className={classes.subcontainer} elevation={0} onClick={handleCardClick}>
        <CardActionArea className={classes.subcontainer}>
          <img
            src={process.env.PUBLIC_URL + "/images/frames/" + frame.sku + "." + frame.image.substr(frame.image.lastIndexOf('.') + 1)}
            alt={frame.sku}
            className={classes.media} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {frame.sku}
            </Typography>
            <Typography variant="body1" color="textPrimary" component="h5">
              {frame.postTitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}