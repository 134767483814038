import { Color } from "./Color";

export interface PPColorsState {
    colors: Color[],
    isLoading: boolean,
}

export const initialPPColorsState: PPColorsState = {
    colors: [],
    isLoading: false,
}