import { CircularProgress, createStyles, FormControlLabel, makeStyles, Paper, Radio, RadioGroup, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { NumericInput, NumericInputState } from "./NumericInput";
import axios from "axios";
import { ColorPicker } from "./ColorPicker";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { initialPreviewState } from "../../types/PreviewState";
import { apiUrl } from "../../settings/apiUrl";
import { Color } from "../../types/Color";
import { usePPColorsContext } from "../../contexts/PPColorsContext";
import log from "loglevel";

const minInputValue = 3;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: 0,
      padding: theme.spacing(3),
      backgroundColor: 'inherit',
      boxSizing: 'border-box',
    },
    subcontainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    horizontalContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    input: {
      marginTop: theme.spacing(3),
    },
    inputFullWidth: {
      width: '100%',
    }
  }),
);

export function PPContentPanel() {

  const classes = useStyles();

  const { pPColorsState, setPPColorsState } = usePPColorsContext();

  const { previewState, setPreviewState } = usePreviewContext();

  const [ppWidthInput, setPpWidthInput] = useState<NumericInputState>({
    value: 5,
    error: false,
    validationMessage: '',
  });

  useEffect(() => {
    async function getPPs() {
      const newPPs = await axios.get(`${apiUrl}/wp/passepartouts/colors`).then(res => {
        let newColors: Color[] = [];
        res.data.map((color: Color) =>
          newColors.push(
            {
              Code: color.Code,
              ID: Number.parseInt(color.ID.toString(), 10),
              Color: `#${color.Color}`
            }
          )
        );
        return newColors;
      }).catch(error => { log.error(error) });
      if (newPPs) {
        setPPColorsState({ colors: newPPs, isLoading: false });
        const { ppColor, ...oldState } = previewState;
        setPreviewState({ ppColor: newPPs[0], ...oldState })
      }
    }
    if (!pPColorsState.colors || (pPColorsState.colors && pPColorsState.colors.length === 0)) {
      getPPs();
    }
  }, []);

  useEffect(() => {
    if (previewState.ppWidth) {
      handleNumericInputChange(previewState.ppWidth);
    }
  }, [previewState.frameHeight, previewState.frameWidth, previewState.frameSize, previewState.ppContent])

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === "pp";
    if (previewState) {
      const { ppContent, ...oldAppState } = previewState;
      setPreviewState({ ...oldAppState, ppContent: newValue });
    }
    else {
      const { ppContent, ...oldAppState } = initialPreviewState;
      setPreviewState({ ...oldAppState, ppContent: newValue })
    }
  }
  const onColorPickerClicked = (color: Color) => {
    if (previewState) {
      const { ppColor, ...oldAppState } = previewState;
      setPreviewState({ ...oldAppState, ppColor: color });
    }
    else {
      const { ppColor, ...oldAppState } = initialPreviewState;
      setPreviewState({ ...oldAppState, ppColor: color })
    }
  }

  const handleNumericInputChange = (newValue: number) => {
    let ppValidationMessage = "";
    let error = false;
    let patternWidth = previewState?.frame?.width ? previewState.frame.width : 4.5;

    if (previewState.ppContent) {
      if (newValue < minInputValue) {
        ppValidationMessage = `Minimalna wartość to ${minInputValue}`;
        error = true;
      }
      if (previewState) {
        if (previewState?.frameSize === "frameInner") {
          const maxValue = Math.min(
            120 - Math.max(previewState.frameHeight, previewState.frameWidth),
            80 - Math.min(previewState.frameHeight, previewState.frameWidth));

          if (Math.max(previewState.frameHeight + newValue, previewState.frameWidth + newValue) > 120
            || Math.min(previewState.frameHeight + newValue, previewState.frameWidth + newValue) > 80) {
            const message = `Maksymalna wartość to ${maxValue}`;
            ppValidationMessage = ppValidationMessage === '' ? message : ppValidationMessage + "\n" + message;
            error = true;
          }
        }
        else {
          const maxValue = Math.min(previewState.frameHeight - 2 * patternWidth, previewState.frameWidth - 2 * patternWidth) / 2
          if (Math.min(previewState.frameHeight - 2 * patternWidth, previewState.frameWidth - 2 * patternWidth) / 2 < newValue) {
            const message = `Maksymalna wartość to ${maxValue}`;
            ppValidationMessage = ppValidationMessage === '' ? message : ppValidationMessage + "\n" + message;
            error = true;
          }
        }
        const { ppWidth, ppValidationError, ...oldAppState } = previewState;
        if (ppWidth !== newValue || ppValidationError !== error) {
          setPreviewState({ ...oldAppState, ppWidth: newValue, ppValidationError: error });
        }

      }
      else {
        const { ppWidth, ppValidationError, ...oldAppState } = initialPreviewState;
        setPreviewState({ ...oldAppState, ppWidth: newValue, ppValidationError: error });
      }
      if (ppWidthInput.error !== error || ppWidthInput.validationMessage !== ppValidationMessage) {
        setPpWidthInput({ error: error, validationMessage: ppValidationMessage, value: 5 });
      }
    } else {
      if (previewState.ppValidationError) {
        const { ppValidationError, ...oldAppState } = previewState;
        setPreviewState({ ...oldAppState, ppValidationError: false });
      }
    }

  }

  return (
    <Paper className={classes.container} elevation={0}>
      <RadioGroup name="ppContent" defaultValue="pp" className={classes.horizontalContainer} onChange={handleChangeRadio}>
        <FormControlLabel
          value="empty"
          label="Brak"
          control={<Radio />}
        />
        <FormControlLabel
          value="pp"
          label="Wybieram Passe Partout"
          control={<Radio />}
        />
      </RadioGroup>
      {previewState?.ppContent &&
        <>
          <NumericInput
            className={classes.input}
            id="ppWidth"
            label="Szerokość Passe Partout"
            validation
            value={previewState.ppWidth}
            error={ppWidthInput.error}
            validationMessage={ppWidthInput.validationMessage}
            handleChange={(event) => handleNumericInputChange(Number.parseInt(event.target.value, 10))}
          />
          {pPColorsState.isLoading && <CircularProgress color="secondary" />}
          {!pPColorsState.isLoading &&
            <>
              <Typography className={classes.input}>Wybrany kolor: {previewState.ppColor?.Code}</Typography>
              <ColorPicker
                className={`${classes.input} ${classes.inputFullWidth}`}
                colors={pPColorsState.colors}
                value={previewState.ppColor}
                onClick={onColorPickerClicked}
              />
            </>
          }
        </>
      }
    </Paper>
  );
}