import { makeStyles, Theme, createStyles, Container, Typography, Paper, CircularProgress, Button } from "@material-ui/core";
import axios from "axios";
import { debounce, findLastIndex } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { apiUrl } from "../../settings/apiUrl";
import { PreviewState } from "../../types/PreviewState";
import wallTexturePng from "./wall_texture.png";
import log from "loglevel";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
    },
    horizontalContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    verticalContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wall: {
      backgroundImage: `url(${wallTexturePng})`,
      backgroundRepeat: 'repeat',
    },
    imageContainer: {
      marginTop: theme.spacing(4),
      width: '90%',
      height: '90%',
    },
    progress: {
      zIndex: 1000,
      position: 'absolute',
    },
    noImagePopUp: {
      width: '60%',
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }
  }),
);

interface VisualizationPanelProps {
  className?: string,
}

export function VisualizationPanel({ className }: VisualizationPanelProps) {
  const classes = useStyles();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { previewState, setPreviewState } = usePreviewContext();
  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);

  async function getPreview(width: number, height: number, previewState: PreviewState) {
    if (!previewState.ppValidationError && !previewState.sizeValidationError) {
      setIsLoading(true);
      const image = await axios.post(
        `${apiUrl}/viz/pattern`,
        {
          sku: previewState.frame?.sku,
          containerWidth: width,
          containerHeight: height,
          frameSize: previewState.frameSize,
          frameWidth: previewState.frameWidth,
          frameHeight: previewState.frameHeight,
          frameContent: previewState.frameContent,
          framePatternWidth: previewState.frame?.width,
          ppContent: previewState.ppContent,
          ppWidth: previewState.ppWidth,
          ppColor: previewState.ppColor?.Color,
          uploadedImage: previewState.uploadedImage,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        return res.data.image64 ?? null;
      }).catch(error => {
        log.error(error);
      });
      const { frameImage, ...oldState } = previewState;
      setPreviewState({ frameImage: image, ...oldState });
      setIsLoading(false);
    }
  }

  const debounceGetPreview = useRef(
    debounce(async (width: number, height: number, previewState: PreviewState) => {
      await getPreview(width, height, previewState);
    },
      1000)
  );

  useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  })

  useEffect(() => {
    if (width > 0 && height > 0 && previewState.frame) {
      debounceGetPreview.current(width, height, previewState);
    }
  }, [
    previewState.frame,
    previewState.frameContent,
    previewState.frameHeight,
    previewState.frameSize,
    previewState.frameWidth,
    previewState.ppContent,
    previewState.ppColor,
    previewState.ppWidth,
    previewState.uploadedImage,
    previewState.ppValidationError,
    previewState.sizeValidationError,
    height,
    width]
  );

  const handleClickCard = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/');
  }

  return (
    <Paper
      className={`${classes.container} ${classes.wall} ${className}`}
      style={{
        backgroundColor: (previewState?.wallColor?.Color) === undefined ? '#ffffff' : previewState?.wallColor?.Color
      }}
    >
      <Container className={classes.verticalContainer}>
        {
          previewState.frameImage &&
          <Typography>Wizualizacja poglądowa. Ramka została wygnerowana automatycznie - w rzeczywistości detale oraz kolory mogą się różnić.
          </Typography>
        }
        <div ref={ref} className={`${classes.imageContainer} ${classes.verticalContainer}`}>
          {previewState.frameImage && <img src={previewState.frameImage} alt="preview" />}
          {isLoading && <CircularProgress color='secondary' className={classes.progress} />}
          {
            !isLoading && previewState.frameImage === null &&
            <Paper
              className={classes.noImagePopUp}
            >
              <Typography
                variant='h6'
              >
                <p>
                  Wygląda na to, że obecnie nie mamy możliwości wyświetlenia wizualizacji wybranej ramy :(
                </p>
                <p>
                  Spróbuj wybrać inną ramę.
                </p>

              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickCard}
              >
                Wybierz inną ramę
              </Button>
            </Paper>
          }
        </div>
      </Container>
    </Paper>
  );
}