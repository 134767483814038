import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import log from "loglevel";
import { AccordianMenu } from "../components/AccordionMenu/AccordianMenu";
import { usePreviewContext } from "../contexts/PreviewContext";
import { Frame } from "../types/Frame";
import { VisualizationPanel } from "../components/VisualizationPanel/VisualizationPanel";
import { apiUrl } from "../settings/apiUrl";
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { paramsToState } from "../helpers/queryParametersHelper";
import { usePPColorsContext } from "../contexts/PPColorsContext";
import { MainToolbar } from "../components/MainToolbar/MainToolbar";

const drawerWidth = 600;

interface StyleProps {
  drawerVisible: boolean,
  topMargin: number,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: (styleProps: StyleProps) => styleProps.drawerVisible ? `calc(100% - ${drawerWidth}px)` : '100%',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      marginTop: (styleProps: StyleProps) => styleProps.topMargin,
    },
  })
)
export function ProductPage() {

  const params: any = useParams();
  const history = useHistory();
  const locationSearch = useLocation().search;

  const { previewState, setPreviewState } = usePreviewContext();
  const { pPColorsState } = usePPColorsContext();

  const [drawerVisible, setDrawerVisible] = useState(true);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const toolbarRef = useRef<HTMLDivElement>(null);

  const classes = useStyles({ drawerVisible: drawerVisible, topMargin: toolbarHeight });

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setToolbarHeight(toolbarRef?.current?.clientHeight ?? 0);
  });

  useEffect(() => {
    if (smallScreen) {
      setDrawerVisible(false);
    }
  }, [smallScreen]);

  useEffect(() => {
    const state = paramsToState(locationSearch, pPColorsState.colors);
    if (state && !!pPColorsState.colors) {
      setPreviewState(state);
      history.replace({
        search: undefined,
      })
    }

    async function getProduct() {
      const newFrame = await axios.get(`${apiUrl}/wp/products/sku/${params.sku}`).then(res => {
        let newFrames: Frame[] = [];
        res.data.map((frame: Frame) => newFrames.push(frame));
        if (newFrames.length === 1) {
          return newFrames[0];
        }
        return undefined;
      }).catch(error => { log.error(error) });
      if (newFrame) {
        if (previewState.ppContent && pPColorsState.colors && !previewState.ppColor) {
          const { frame, ppColor, ...oldPreviewState } = previewState;
          setPreviewState({ frame: newFrame, ppColor: pPColorsState.colors[0], ...oldPreviewState })
        } else {
          const { frame, ...oldPreviewState } = previewState;
          setPreviewState({ frame: newFrame, ...oldPreviewState })
        }
      }
    }

    if (pPColorsState.colors && pPColorsState.colors.length > 0) {
      getProduct();
    }

  }, [pPColorsState.colors])

  return (
    <>
      {
        smallScreen && <MainToolbar
          ref={toolbarRef}
          setDrawerVisible={setDrawerVisible}
          drawerVisible={drawerVisible}
        />
      }
      {drawerVisible && <AccordianMenu drawerMargin={toolbarHeight} />}
      <VisualizationPanel className={classes.appBar} />
    </>
  );
}