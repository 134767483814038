import { Button, CardActions, CardContent, Container, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { usePreviewContext } from "../../contexts/PreviewContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'inherit',
    },
    subcontainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 400,
      margin: theme.spacing(2),
      backgroundColor: 'inherit',
    },
    media: {
      height: 300,
    },
    actionButton: {
      width: '100%',
    }
  }),
);

export function PickFramePanel() {
  const classes = useStyles();
  const history = useHistory();

  const { previewState } = usePreviewContext();

  const handleClickCard = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/');
  }

  return (
    <Paper className={classes.container} elevation={0}>
      <Paper className={classes.subcontainer} elevation={0}>
        <img
          src={process.env.PUBLIC_URL + "/images/frames/" + previewState?.frame?.sku + "." + previewState?.frame?.image.substr(previewState?.frame?.image.lastIndexOf('.') + 1)}
          alt={previewState?.frame?.sku}
          className={classes.media}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {previewState?.frame?.sku}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {previewState?.frame?.postTitle}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            className={classes.actionButton}
            onClick={handleClickCard}
          >
            Wybierz inną ramę
          </Button>
        </CardActions>
      </Paper>
    </Paper >
  );
}