import { Accordion, AccordionDetails, AccordionSummary, Avatar, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { PropsWithChildren, useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { triggerAsyncId } from "async_hooks";

interface StyleProps {
  backgroundColor: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordianSummaryBase: {
      borderColor: '#e0e0e0',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: 'bold',
      flexBasis: theme.spacing(6),
      flexShrink: 0,
      marginLeft: theme.spacing(4),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
    },
    accordionSummary: {
      alignItems: 'center',
    },
    details: {
      backgroundColor: '#F8F8F8',
    },
    avatar: {
      display: 'flex',
      marginLeft: 'auto',
    },
    borderBlack: {
      border: 'solid 3px black',
    },
    color: {
      backgroundColor: (styleProps: StyleProps) => styleProps.backgroundColor,
    },
    expandIcon: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    }
  }),
);

export interface AccordionBaseProps {
  step: string,
  header: string,
  color?: string,
  image?: string,
  expanded?: boolean,
  validationError?: boolean,
  onClick?: (clicked: string) => void,
}

export function AccordionBase({ step, header, children, expanded, color, image, validationError, onClick }: PropsWithChildren<AccordionBaseProps>) {
  const styleProps: StyleProps = { backgroundColor: 'white' };
  if (color) {
    styleProps.backgroundColor = color;
  }
  const classes = useStyles(styleProps);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} className={classes.accordianSummaryBase}>
        <AccordionSummary
          classes={{
            content: classes.accordionSummary,
          }}
          expandIcon={<ExpandMoreIcon color="primary" className={classes.expandIcon} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          onClick={() => {
            if (onClick) {
              onClick(step);
            }
          }}
        >
          <Typography className={classes.heading} color="textPrimary">{step}</Typography>
          <Typography className={classes.secondaryHeading} color={validationError ? "error" : "textPrimary"}>{header}</Typography>
          {color && !expanded && <Avatar alt='selected color' className={`${classes.color} ${classes.avatar} ${classes.borderBlack}`}> </Avatar>}
          {image && !expanded && <Avatar alt='selected frame' src={image} className={classes.avatar} />}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}