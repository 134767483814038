import { Route, Switch } from "react-router-dom";
import { MainPage } from "../pages/MainPage";
import { ProductPage } from "../pages/ProductPage";


export function AppRoutes() {
    return (
        <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/rama/:sku" component={ProductPage} />
        </Switch>
    );
}