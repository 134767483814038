import { Button, Container, createStyles, Drawer, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { useMemo, useState } from "react";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { stateToParams } from "../../helpers/queryParametersHelper";
import { AccordionBase } from "./AccordionBase";
import { FrameContentPanel } from "./FrameContentPanel";
import { FrameSizePanel } from "./FrameSizePanel";
import { PickFramePanel } from "./PickFramePanel";
import { PPContentPanel } from "./PPContentPanel";
import { PreviewSettingsPanel } from "./PreviewSettingsPanel";


const drawerWidth = 600;

interface StyleProps {
  marginTop: string | number,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    // appBar: {
    //   transition: theme.transitions.create(['margin', 'width'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    //   }),
    // },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
      // transition: theme.transitions.create(['margin', 'width'], {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },

    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      flexShrink: 0,
    },
    drawerPaper: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      top: (styleProps: StyleProps) => styleProps.marginTop,
      width: drawerWidth,
      zIndex: theme.zIndex.drawer,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    fullWidth: {
      width: '100%',
    },
    fiftyPercentWidth: {
      width: '50%',
    },
    topMargin4: {
      marginTop: theme.spacing(4),
    },
    topMargin2: {
      marginTop: theme.spacing(2),
    },
    bottomMargin4: {
      marginBottom: theme.spacing(4),
    },
    accordianFunctionButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'center',
    }
  }),
);

interface AccordianMenuProps {
  drawerMargin: number,
}

export function AccordianMenu({ drawerMargin }: AccordianMenuProps) {
  const classes = useStyles({ marginTop: drawerMargin });

  const [selected, setSelected] = useState<string | undefined>('2.');

  const { previewState } = usePreviewContext();

  const onClicked = (clicked: string) => {
    if (selected === clicked) {
      setSelected(undefined);
    }
    else {
      setSelected(clicked);
    }
  }

  const pickFrameHeader = useMemo(() => previewState?.frame ? `Rama: ${previewState.frame.sku}` : "Wybierz ramę", [previewState]);

  const frameSizeHeader = useMemo(() => {
    if (previewState.sizeValidationError) {
      return `Rozmiar ${previewState?.frameSize === 'frameInner' ? "obrazu: " : "ramy: "} wprowadzono nieprawidłowe wymiary`;
    }
    return `Rozmiar ${previewState?.frameSize === 'frameInner' ? "obrazu: " : "ramy: "} ${previewState?.frameWidth} cm x ${previewState?.frameHeight} cm`;
  },
    [previewState.frameSize, previewState.frameWidth, previewState.frameHeight, previewState.sizeValidationError]
  );

  const frameContentHeader = useMemo(() => {
    let header = "Zawartość ramy";
    switch (previewState?.frameContent) {
      case "empty":
        header += ": pusta rama";
        break;
      case "glass":
        switch (previewState.glass) {
          case "2":
            header += ": szyba 2mm";
            break;
          case "3":
            header += ": szyba 3mm";
            break;
          case "a":
            header += ": szyba antyrefleks";
            break;
          case "p":
            header += ": pleksi";
            break;
        }
        break;
      case "mirror":
        header += ": lustro"
        break;
      case "chalkBoard":
        header += ": tablica kredowa"
        break;
      case "corkBoard":
        header += ": tablica korkowa"
        break;
    }
    return header;
  },
    [previewState]
  );

  const ppConentPanelHeader = useMemo(() => {
    let header = "Passe partout: ";
    if (previewState.ppValidationError) {
      header += 'wprowadzono nieprawidłowy wymiar';
    } else {
      header += previewState?.ppContent ? `${previewState.ppWidth} cm ${previewState.ppColor?.Code ? previewState.ppColor?.Code : ""}` : "brak";
    }
    return header;
  }, [previewState.ppContent, previewState.ppWidth, previewState.ppColor?.Code, previewState.ppValidationError])

  const openShopTab = (url?: string) => {
    if (url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let i = 0;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Typography
        aria-label='Header'
        color='textPrimary'
        variant='h4'
        align='center'
        className={`${classes.topMargin4} ${classes.bottomMargin4}`}
      >
        Skomponuj swoją ramę!
      </Typography>
      <AccordionBase
        step={`${++i}.`}
        expanded={selected === `${i}.`}
        header={pickFrameHeader}
        onClick={onClicked}
        image={previewState.frame?.image}
      >
        <PickFramePanel />
      </AccordionBase>
      <AccordionBase
        step={`${++i}.`}
        expanded={selected === `${i}.`}
        header={frameSizeHeader}
        onClick={onClicked}
        validationError={previewState.sizeValidationError}
      >
        <FrameSizePanel />
      </AccordionBase>
      <AccordionBase
        step={`${++i}.`}
        expanded={selected === `${i}.`}
        header={frameContentHeader}
        onClick={onClicked}
      >
        <FrameContentPanel />
      </AccordionBase>
      {
        "glass" === previewState.frameContent &&
        <AccordionBase
          step={`${++i}.`}
          expanded={selected === `${i}.`}
          header={ppConentPanelHeader}
          onClick={onClicked}
          color={previewState.ppContent ? previewState.ppColor?.Color : undefined}
          validationError={previewState.ppValidationError}
        >
          <PPContentPanel />
        </AccordionBase>
      }
      <AccordionBase
        step={`${++i}.`}
        expanded={selected === `${i}.`}
        header="Podgląd"
        onClick={onClicked}
      >
        <PreviewSettingsPanel />
      </AccordionBase>
      <Container className={classes.accordianFunctionButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={`${classes.fiftyPercentWidth}`}
          onClick={() => {
            let a = document.createElement("a");
            a.href = previewState.frameImage;
            a.download = "Oprawiamywramy.png";
            a.click();
          }}
        >
          Pobierz wizualizację
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.fiftyPercentWidth} ${classes.topMargin2}`}
          onClick={() => openShopTab(stateToParams(previewState))}
        >
          Sprawdź cenę
        </Button>
      </Container>
    </Drawer >
  );
}