import { Container, createStyles, FormControlLabel, makeStyles, Paper, Radio, RadioGroup, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { usePreviewContext } from "../../contexts/PreviewContext";
import { initialPreviewState, PreviewState } from "../../types/PreviewState";
import { NumericInput, NumericInputState } from "./NumericInput";
import frameSizePng from "./wyborRama.png";
import frameInnerSizePng from "./wyborObraz.png";
import { debounce } from "lodash";

const maxInputValue = 150;
const minInputValue = 5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'inherit',
    },
    innerPaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: 230,
      margin: theme.spacing(2),
      backgroundColor: 'inherit',
    },
    subcontainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    reverseSubcontainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
    },
    validatedInput: {
      marginTop: theme.spacing(3),
    },
    numericInputRoot: {
      paddingTop: '10px',
    },
    phoneLink: {
      textDecoration: 'none',
    }
  }),
);

type FrameDimensions = {
  frameWidth: NumericInputState,
  frameHeight: NumericInputState,
}

export function FrameSizePanel() {
  const classes = useStyles();
  const { previewState, setPreviewState } = usePreviewContext();

  const [frameSizeState, setFrameSizeState] = useState<FrameDimensions>({
    frameHeight: {
      value: previewState.frameHeight,
      error: false,
      validationMessage: '',
    },
    frameWidth: {
      value: previewState.frameWidth,
      error: false,
      validationMessage: '',
    }
  });



  const handleChangeFrameSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (previewState) {
      const { frameSize, ...oldAppState } = previewState;
      const newFrameSize = event.target.value === 'frame' ? 'frame' : 'frameInner';
      setPreviewState({ ...oldAppState, frameSize: newFrameSize });
    }
    else {
      const { wallColor, ...oldAppState } = initialPreviewState;
      const newFrameSize = event.target.value === 'frame' ? 'frame' : 'frameInner';
      setPreviewState({ ...oldAppState, frameSize: newFrameSize })
    }
  }

  const handleNumericInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number.parseInt(event.target.value);
    if (event.target.id === 'frameWidth') {
      setFrameSizeState({
        frameWidth: {
          value: newValue,
          error: false,
          validationMessage: '',
        },
        frameHeight: frameSizeState.frameHeight
      });
      // if (previewState) {
      //   const { frameWidth, ...oldAppState } = previewState;
      //   setPreviewState({ ...oldAppState, frameWidth: newValue });
      // }
      // else {
      //   const { frameWidth, ...oldAppState } = initialPreviewState;
      //   setPreviewState({ ...oldAppState, frameWidth: newValue })
      // }
    }
    if (event.target.id === 'frameHeight') {
      setFrameSizeState({
        frameHeight: {
          value: newValue,
          error: false,
          validationMessage: '',
        },
        frameWidth: frameSizeState.frameWidth
      });
      // if (previewState) {
      //   const { frameHeight, ...oldAppState } = previewState;
      //   setPreviewState({ ...oldAppState, frameHeight: newValue });
      // }
      // else {
      //   const { frameHeight, ...oldAppState } = initialPreviewState;
      //   setPreviewState({ ...oldAppState, frameHeight: newValue })
      // }
    }
  }

  const debounceNumericInput = useRef(
    debounce((appState, frameSizeState) => {
      changeValueAndValidate(appState, frameSizeState);
    }, 1000)
  );

  useEffect(() => {
    if (previewState?.frameHeight && previewState?.frameWidth) {
      debounceNumericInput.current(previewState, frameSizeState);
    }
  }, [frameSizeState.frameHeight.value, frameSizeState.frameWidth.value])

  function changeValueAndValidate(previewState: PreviewState, frameSizeState: FrameDimensions) {
    let newFrameSizeState: FrameDimensions = {
      frameWidth: {
        value: frameSizeState.frameWidth.value,
        error: false,
        validationMessage: '',
      },
      frameHeight: {
        value: frameSizeState.frameHeight.value,
        error: false,
        validationMessage: '',
      }
    };
    if (previewState) {
      if (previewState.frameWidth < minInputValue) {
        newFrameSizeState.frameWidth.error = true;
        newFrameSizeState.frameWidth.validationMessage = `Minimalna wartość to ${minInputValue}`;
      } else if (previewState.frameWidth > maxInputValue) {
        newFrameSizeState.frameWidth.error = true;
        newFrameSizeState.frameWidth.validationMessage = `Maksymalna wartość to ${maxInputValue}`;
      }
      if (previewState.frameHeight < minInputValue) {
        newFrameSizeState.frameHeight.error = true;
        newFrameSizeState.frameHeight.validationMessage = `Minimalna wartość to ${minInputValue}`;
      } else if (previewState.frameHeight > maxInputValue) {
        newFrameSizeState.frameHeight.error = true;
        newFrameSizeState.frameHeight.validationMessage = `Maksymalna wartość to ${maxInputValue}`;
      }
      const { sizeValidationError, frameHeight, frameWidth, ...oldAppState } = previewState;
      const newError = newFrameSizeState.frameHeight.error || newFrameSizeState.frameWidth.error;
      if (
        newError !== sizeValidationError ||
        frameHeight !== frameSizeState.frameHeight.value ||
        frameWidth !== frameSizeState.frameWidth.value
      ) {
        setPreviewState({
          ...oldAppState,
          frameHeight: frameSizeState.frameHeight.value,
          frameWidth: frameSizeState.frameWidth.value,
          sizeValidationError: newFrameSizeState.frameHeight.error || newFrameSizeState.frameWidth.error
        });
      }

      if (JSON.stringify(frameSizeState) !== JSON.stringify(newFrameSizeState)) {
        setFrameSizeState(newFrameSizeState);
      }
    }
  }

  return (
    <Paper className={classes.container} elevation={0}>
      <RadioGroup
        name="frameSize"
        defaultValue={previewState?.frameSize}
        className={classes.subcontainer}
        onChange={handleChangeFrameSize}
      >
        <Paper className={classes.innerPaper} elevation={0} square>
          <img src={frameInnerSizePng} alt="Wybor obraz" width={108} />
          <FormControlLabel
            value="frameInner"
            control={<Radio color="secondary" />}
            label="Wymiar obrazu"
          />
        </Paper>
        <Paper className={classes.innerPaper} elevation={0} square>
          <img src={frameSizePng} alt="Wybor rama" width={120} />
          <FormControlLabel
            value="frame"
            control={<Radio color="secondary" />}
            label="Zewnętrzny wymiar ramy"
          />
        </Paper>
      </RadioGroup>
      <Container className={previewState?.frameSize === "frameInner" ? classes.subcontainer : classes.reverseSubcontainer}>
        <Paper className={classes.innerPaper} elevation={0} square>
          <NumericInput
            className={classes.validatedInput}
            id="frameWidth"
            label="Szerokość"
            value={frameSizeState.frameWidth.value}
            validation
            error={frameSizeState.frameWidth.error}
            validationMessage={frameSizeState.frameWidth.validationMessage}
            handleChange={handleNumericInputChange}
          />
          <NumericInput
            className={classes.validatedInput}
            id="frameHeight"
            label="Wysokość"
            value={frameSizeState.frameHeight.value}
            validation
            error={frameSizeState.frameHeight.error}
            validationMessage={frameSizeState.frameHeight.validationMessage}
            handleChange={handleNumericInputChange}
          />
        </Paper>
        <Paper className={classes.innerPaper} elevation={0} square />
      </Container>
      <Typography>Potrzebujesz większej ramy? Zadzwoń <a className={classes.phoneLink} href="tel:692 821 422">692 821 422</a>!</Typography>
    </Paper>
  );
}