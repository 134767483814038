import { Color } from "./Color";
import { Frame } from "./Frame";

export type PreviewState = {
    frame?: Frame,
    frameSize: "frame" | "frameInner",
    frameWidth: number,
    frameHeight: number,
    frameContent: "empty" | "glass" | "chalkBoard" | "corkBoard" | "mirror",
    glass?: "2" | "3" | "a" | "p",
    ppContent: boolean,
    ppWidth?: number,
    ppColor?: Color,
    wallColor?: Color,
    frameImage: any,
    uploadedImage: any,
    ppValidationError: boolean,
    sizeValidationError: boolean,
}

export const initialPreviewState: PreviewState = {
    frame: undefined,
    frameContent: "glass",
    frameHeight: 30,
    frameWidth: 40,
    frameSize: "frameInner",
    frameImage: undefined,
    uploadedImage: null,
    ppContent: true,
    wallColor: { Code: "white", Color: "#ffffff", ID: 1 },
    glass: "2",
    ppColor: undefined,
    ppWidth: 5,
    ppValidationError: false,
    sizeValidationError: false,
};