import { Button, CircularProgress, Container, createStyles, Drawer, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import axios from "axios";
import { last } from "lodash";
import log from "loglevel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFiltersContext } from "../../contexts/FiltersContext";
import { apiUrl } from "../../settings/apiUrl";
import { Filter, FilterGroup } from "../../types/Filter";
import { FilterMultiSelect } from "./FilterMultiSelect";

const drawerWidth = 400;

interface StyleProps {
  marginTop: string | number,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      top: (styleProps: StyleProps) => styleProps.marginTop,
      zIndex: theme.zIndex.drawer,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    verticalContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    fullWidth: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    header: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      textTransform: 'uppercase',
    },
    mint: {
      color: '#8bcbb6',
    },
    pointer: {
      cursor: 'pointer',
    }
  }),
);

export interface FrameFilterProps {
  productsCount?: number,
  drawerMargin: number,
}

export function FrameFilter({ productsCount, drawerMargin }: FrameFilterProps) {
  const styleProps: StyleProps = { marginTop: 0 };
  if (drawerMargin) {
    styleProps.marginTop = drawerMargin;
  }
  const classes = useStyles(styleProps);
  const { filtersState, setFiltersState } = useFiltersContext();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function loadFilters() {
      setIsLoading(true);
      let newFilterGroups: FilterGroup[] = [];
      const { filterGroups } = filtersState;
      for (let i = 0; i < filterGroups.length; i++) {
        const data = await axios.get(`${apiUrl}/wp/product/attribute/${filterGroups[i].filterName}`).then(res => {
          let newData: Filter[] = [];
          res.data.map((record: any) => newData.push({ id: Number.parseInt(record.id), name: record.nazwa }));
          return newData;
        }).catch(error => {
          log.error(error);
          return [];
        });
        newFilterGroups.push({ filterName: filterGroups[i].filterName, filters: data, filterHeader: filterGroups[i].filterHeader });
      }
      setFiltersState({ search: filtersState.search, filterGroups: newFilterGroups, appliedFilters: filtersState.appliedFilters });
      setIsLoading(false);
    }
    loadFilters();
  }, [])

  const handleChangeFilter = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      const newValue = Number.parseInt(last(event.target.value as any[]) as string);
      const { search, appliedFilters, ...oldState } = filtersState;
      let filters = filtersState.appliedFilters;
      if (appliedFilters.includes(newValue)) {
        filters = filters.filter(f => f !== newValue);
      }
      else {
        filters.push(newValue);
      }
      setFiltersState({ search: '', appliedFilters: filters, ...oldState });
      setSearch('');
    }
  }, [filtersState, setFiltersState, setSearch]);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearch(event.target.value ?? '');
  }, [setSearch]);

  const handleSubmitSearch = useCallback(() => {
    const { appliedFilters, search: oldSearch, ...oldState } = filtersState;
    setFiltersState({ search, appliedFilters: [], ...oldState });
  }, [search, setFiltersState]);

  const handleDeleteFilter = useCallback((event: React.MouseEvent, filter: string, filterGroup: string) => {
    event.preventDefault();
    const { search, appliedFilters, ...oldState } = filtersState;
    const group = filtersState.filterGroups.find(group => group.filterName === filterGroup);
    const foundFilter = group?.filters.find(f => f.name === filter);
    const newFilters = appliedFilters.filter(f => f !== foundFilter?.id);

    setFiltersState({ search: '', appliedFilters: newFilters, ...oldState });
    setSearch('');

  }, [filtersState, setFiltersState, setSearch])

  const getSelectedFiltersForGroup = useCallback(
    (filterGroup: FilterGroup) => {
      const result = filterGroup.filters.filter(f => filtersState.appliedFilters.indexOf(f.id) >= 0);
      return result;
    },
    [filtersState.appliedFilters],
  )

  const handleRemoveFiltersClick = () => {
    const { appliedFilters, ...oldFilters } = filtersState;
    setFiltersState({ appliedFilters: [], ...oldFilters });
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Container className={classes.verticalContainer}>
        <Typography variant="h5" className={classes.header} color='secondary'>Filtruj ramki</Typography>
        <TextField
          className={classes.fullWidth}
          aria-label='Szukaj ramy'
          color="primary"
          size="small"
          label="Szukaj"
          value={search}
          variant="standard"
          InputProps={{
            endAdornment: <Search className={classes.pointer} onClick={handleSubmitSearch} />
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmitSearch();
            }
          }}
          onChange={handleChangeSearch}
        />
        {
          isLoading && <CircularProgress color="secondary" />
        }
        {
          !isLoading && productsCount !== undefined && productsCount > 0 &&
          <Typography variant="h6" className={`${classes.header} ${classes.mint}`}>Znalezione wzory ram: {productsCount}</Typography>
        }
        {
          !isLoading && filtersState.filterGroups.map((group: FilterGroup) => {
            return (
              <FilterMultiSelect
                key={group.filterName}
                filterGroup={group}
                selectedFilters={getSelectedFiltersForGroup(group)}
                onChange={handleChangeFilter}
                handleDelete={handleDeleteFilter}
              />
            );
          })
        }
        {
          filtersState.appliedFilters.length > 0 &&
          <Button
            className={classes.fullWidth}
            variant="contained"
            color="primary"
            onClick={handleRemoveFiltersClick}
          >
            Usuń filtry
          </Button>
        }
      </Container>
    </Drawer>
  );
}