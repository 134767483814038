import { createContext, PropsWithChildren, useContext, useState } from "react";
import { FiltersState, initialFiltersState } from "../types/FiltersState";

interface FiltersContextType {
    filtersState: FiltersState,
    setFiltersState: (state: FiltersState) => void,
}

const FiltersContext = createContext<FiltersContextType>({
    filtersState: initialFiltersState,
    setFiltersState: () => { },
});

export function useFiltersContext() {
    return useContext(FiltersContext);
}

export function FiltersContextProvider({ children }: PropsWithChildren<FiltersContextType>) {
    const [filtersState, setFiltersState] = useState<FiltersState>(initialFiltersState);

    return (
        <FiltersContext.Provider
            value={{
                filtersState: filtersState,
                setFiltersState: (newState: FiltersState) => setFiltersState(newState),
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}