import { createContext, PropsWithChildren, useContext, useState } from "react";
import { initialProductsState, ProductsState } from "../types/ProductsState";

interface ProductsContextType {
    productsState: ProductsState,
    setProductsState: (state: ProductsState) => void,
}

const ProductsContext = createContext<ProductsContextType>({
    productsState: initialProductsState,
    setProductsState: () => { },
});

export function useProductsContext() {
    return useContext(ProductsContext);
}

export function ProductsContextProvider({ children }: PropsWithChildren<ProductsContextType>) {
    const [productsState, setProductsState] = useState<ProductsState>(initialProductsState);

    return (
        <ProductsContext.Provider
            value={{
                productsState: productsState,
                setProductsState: (newState: ProductsState) => setProductsState(newState),
            }}
        >
            {children}
        </ProductsContext.Provider>
    );
}