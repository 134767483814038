import './App.css';
import { PreviewContextProvider } from './contexts/PreviewContext';
import { initialPreviewState } from './types/PreviewState';
import { AppRoutes } from './AppRoutes/AppRoutes';
import { ProductsContextProvider } from './contexts/ProductsContext';
import { initialProductsState } from './types/ProductsState';
import { BrowserRouter } from 'react-router-dom';
import { FiltersContextProvider } from './contexts/FiltersContext';
import { initialFiltersState } from './types/FiltersState';
import { noop } from 'lodash';
import { PPColorsContextProvider } from './contexts/PPColorsContext';
import { initialPPColorsState } from './types/PPColorsState';
import { MainToolbar } from './components/MainToolbar/MainToolbar';

function App() {
  return (
    <BrowserRouter>
      <ProductsContextProvider productsState={initialProductsState} setProductsState={noop}>
        <FiltersContextProvider filtersState={initialFiltersState} setFiltersState={noop}>
          <PreviewContextProvider previewState={initialPreviewState} setPreviewState={noop}>
            <PPColorsContextProvider pPColorsState={initialPPColorsState} setPPColorsState={noop}>
              <AppRoutes />
            </PPColorsContextProvider>
          </PreviewContextProvider>
        </FiltersContextProvider>
      </ProductsContextProvider>
    </BrowserRouter>

  );
}

export default App;
