import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Color } from "../../types/Color";
interface ColorPickerProps {
  colors: Color[],
  width?: number,
  tileSize?: number,
  value?: Color,
  className?: string,
  onClick?: (color: Color) => void;
}

const TILE_SIZE = 50;

const useStyles = (width?: number, tileWidth?: number) => makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: width ? theme.spacing(width) : '100%',
      display: 'grid',
      justifyContent: 'space-between',
      gridTemplateColumns: `repeat(auto-fill, ${TILE_SIZE + (2 * theme.spacing(1))}px)`,
      padding: theme.spacing(2),
    },
    tile: {
      height: TILE_SIZE,
      width: TILE_SIZE,
      marginTop: theme.spacing(1),
      alignSelf: 'center',
      justifySelf: 'center',
      borderRadius: '50%',
    },
    tileSelected: {
      borderWidth: '5px',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      borderRadius: '2px',
    },
    tileSelectedLight: {
      borderColor: 'white',
    },
    tileSelectedDark: {
      borderColor: 'black',
    },
  }),
);

export function ColorPicker({ colors, value, tileSize, width, onClick, className }: ColorPickerProps) {
  const classes = useStyles(width, tileSize)();

  return (
    <Container className={`${classes.container} ${className}`}>
      {
        colors.map((color: Color) => {
          return (
            <div
              key={color.ID}
              title={color.Code}
              className={
                value?.ID === color.ID ?
                  `${classes.tile} ${classes.tileSelected} ${classes.tileSelectedDark}` :
                  `${classes.tile}`
              }
              style={{ background: color.Color }}
              onClick={() => {
                if (onClick) {
                  onClick({ ID: color.ID, Code: color.Code, Color: color.Color });
                }
              }}
            />);
        })
      }
    </Container>
  );
}

function lightOrDark(color: any) {

  let r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )
    );

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  }
  else {
    return 'dark';
  }
}